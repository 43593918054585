@import '~bootstrap/scss/bootstrap';

$fontFamily: "Manrope", sans-serif;

:root {
    --bg-light: #F8F9FB;
    --bg-black: #1C1D1F;
    --bg-black-0E0E0E: #0E0E0E;
    --bg-black-2A2B2F: #2A2B2F;
    --text-black: #1C1D1F;
    --text-muted: #ABADB4;
    --text-gray: #BDBEC1;
    --text-link: #00A3FF;
    --border-card: #D9DBE0;
    --border: #45474A;
    --muted: #62646A;
    --white: #fff;
    --black: #000;
    --red: #FB4954;
    --green: #2EF4A1;
    --red-light: rgba(251, 73, 84, 0.1);
    --green-light: #20E2D0;
    --blue: #00C2FF;
    --blue-light: rgba(73, 101, 251, 0.07);
    --bs-border-color: var(--border);



    // type
    --100: 100px;
    --40: 40px;
    --36: 36px;
    --28: 28px;
    --24: 24px;
    --22: 22px;
    --20: 20px;
    --19: 18px;
    --18: 17px;
    --16: 15px;
    --14: 14px;
    --13: 13px;
    --12: 12px;
    --9: 9px;


    @media screen and (max-width: 1199px) {
        --100: 50px;
        --40: 28px;
        --28: 22px;
        --16: 14px;        
    }

    @media screen and (max-width: 767px) {
        --100: 30px;
        --40: 22px;
        --28: 24px;
        --24: 22px;
        --22: 20px;
        --20: 18px;
        --19: 17px;
        --18: 16px;
        --16: 14px;
        --14: 12px;
    }

    @media screen and (max-width: 575px) {
        --100: 25px;
        --36: 26px;
        --28: 22px;
        --24: 20px;
        --22: 18px;
        --20: 16px;
        --19: 16px;
        --18: 14px;
        --16: 12px;
    }
}

@import "./asserts/SCSS/mixin";
@import "./asserts/SCSS/typo";

$space: 8px;


:not(.overflow-auto):-webkit-scrollbar {
    width: 6px;
}
:not(.overflow-auto)::-webkit-scrollbar-thumb {
    background: var(--black);
    border-radius: 12px;
}
:not(.overflow-auto)::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: rgba(#000, 0.1);
}

.sidebar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.sidebar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
body{
    line-height: 1.4;
    font-weight: 400;
    overflow-x: hidden;
    color: var(--white);
    font-family: $fontFamily;
    background-color: var(--bg-black);

    @extend .text-16;
}

a{
    text-decoration: none;
}

.b2{
    @extend .text-16, .text-500;
}

.b1{
    @extend .text-18, .text-500;
}

.grad{
    @extend .text-18, .text-700;
    background: rgb(32,226,208);
    color: var(--text-black) !important;
    background: -moz-linear-gradient(90deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    background: linear-gradient(90deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#20e2d0",endColorstr="#00c2ff",GradientType=1);
}

.text-grad{
    background: rgb(32,226,208);
    background: -moz-linear-gradient(180deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    background: linear-gradient(180deg, rgba(32,226,208,1) 0%, rgba(0,194,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#20e2d0",endColorstr="#00c2ff",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.overflow{
    &-xl-hidden{
        @media screen and (min-width: 1199px) {
            overflow: hidden;
        }
    }
}

.container{
    width: 100%;
    // max-width: 1600px;
    max-width: 1465px;
    padding-left: $space;
    padding-right: $space;

    @media screen and (max-width: 1199px) {
        padding-left: calc($space - 30px);
        padding-right: calc($space - 30px);
    }

    &-fluid{
        padding-left: calc($space - 20px);
        padding-right: calc($space - 20px);

        @media screen and (max-width: 1199px) {
            padding-left: calc($space - 30px);
            padding-right: calc($space - 30px);
        }
    }

    &-sm{
        max-width: 1100px;
    }
}

a, .btn, button{
    @extend .ease;
}

.btn{
    @extend .text-18, .text-700;
    border-radius: 10px;
    padding: 15px;

    @media screen and (max-width: 767px) {
        padding: 10px;
    }

    @media screen and (max-width: 575px) {
        padding: 10px;
    }

    &-grad{
        @extend .grad;

        &.outline{
            z-index: 1;
            position: relative;

            span{
                @extend .text-grad;
            }

            &:before{
                background-color: var(--bg-black-2A2B2F);
                border-radius: inherit;
                position: absolute;
                content: "";
                z-index: -1;
                bottom: 1px;
                right: 1px;
                left: 1px;
                top: 1px;
            }
        }
    }

    &-red{
        border-color: var(--red) !important;
        background-color: var(--red);
        color: var(--bg-black);

        &-outline{
            border: 1px solid var(--red);
            background-color: transparent;
            color: var(--red);

            &:hover{
                background-color: var(--red);
                color: var(--white);
            }
        }
    }

    &-sm{
        padding: 10px 20px;
        font-size: var(--14);
    }
    
    &-xs{
        padding: 6px 16px;
        border-radius: 25px;
        @extend .text-16, .text-700;
    }

    &-dark{
        background-color: var(--bg-black-2A2B2F);

        span{
            @extend .text-grad;
        }
    }

    &-reset{
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        background: none;

        &:after{
            display: none;
        }
    }

    &-black{
        background-color: var(--bg-black);
        border: 1px solid var(--border);
        @extend .text-16, .text-600;
        color: var(--text-gray);

        &:hover{
            background-color: var(--bg-black);
        }
    }

    &-md{
        padding: 10px 25px;
    }

    &-link{
        background-color: transparent;
        color: var(--text-link);
        text-decoration: none;
        font-weight: 600;
        border: none;
        @extend .b2;
        padding: 0;

        &:hover{
            color: var(--text-gray);
        }
    }

    &-white{
        background-color: var(--white);
        border: 1px solid var(--border);

        &:hover{
            background-color: var(--border);
        }

        &-outline{
            border: 1px solid rgba(#fff, 0.1);
            color: var(--white);

            &:hover{
                background-color: var(--white);
                color: var(--text-link);
            }
        }
    }

    &-icon{
        background-position: left 18px center;
        background-repeat: no-repeat;
        background-size: auto;
        @extend .text-16;
    }

    &-filter{
        width: 100%;
        height: 58px;
        display: flex;
        line-height: 1;
        border: 1px solid var(--border) !important;

        &:after{
            display: none;
        }
    }

    &-back{
        margin-left: -40px;
        margin-top: 5px;

        @media screen and (max-width: 1199px) {
            margin-left: 0;
        }
    }

    &-menu{
        @media screen and (min-width: 1199px) {
            display: none;
        }

        img{
            display: block;
        }
    }

    &-toggle{
        padding: 10px;
        border: none;
        background-color: var(--bg-black-2A2B2F);

        &.active{
            background-color: var(--blue);

            span{
                color: var(--text-black);
            }
        }

        &:hover{
            background-color: var(--bg-black-0E0E0E);
        }

        &:not(.active){
            span{
                @extend .text-grad;
            }
        }

    }

    &:disabled{
        border-color: var(--border) !important;
        background: var(--border) !important;
        color: var(--text-gray) !important;
        opacity: 1;
    }
}

.input-group{
    border: 1px solid var(--border);
    border-radius: 10px;

    .form{
        &-control{
            border: none;
        }
    }
    
    .btn{
        @extend .text-500, .text-16;
        color: var(--muted);

        &:hover{
            color: var(--text-gray);
        }
    }
}

.form{
    &-check{
        &-input{
            margin-top: 3px;
        }

        &-label{
            margin-left: 3px;
        }
    }

    &-floating{
        label{
            color: var(--text-gray);
        }
    }

    &-select{
        option{
            background-color: var(--border);

            &:hover{
                background-color: var(--blue);
            }
        }
    }

    &-control, &-select{
        @extend .b2;
        padding: 1.1em 1em;
        // color: var(--white);
		border-radius: 10px;
        font-family: $fontFamily;
        color: var(--white) !important;
		border: 1px solid var(--border) !important;
        background-color: var(--bg-black) !important;

        &[disabled]{
            opacity: 0.5 !important;
        }

        &:hover{
			border-color: var(--bg-black);

			&::-webkit-input-placeholder {
			  color: var(--text-gray);
			}
			&:-ms-input-placeholder {
			  color: var(--text-gray);
			}
			&::placeholder {
			  color: var(--text-gray);
			}
		}

		&:focus{
			border-color: var(--text-black);
			box-shadow: none;
		}

		&::-webkit-input-placeholder {
            color: var(--text-gray);
        }
        &:-ms-input-placeholder {
            color: var(--text-gray);
        }
        &::placeholder {
            color: var(--text-gray);
        }
    }

    &-check{
        &-input{
            --bs-border-color: rgba(0,0,0, 0.33);
            --bs-border-width: 2px;
            height: 19px;
            width: 19px;

            &:checked{
                background-color: #333;
                border-color: #333;
            }
        }
    }

    &-search{
        width: 100%;
        max-width: 420px;
        border-radius: 8px;
        background-color: var(--white);

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        .btn{
            color: var(--black);
            padding: 10px;
            border: none;
            
            svg{
                opacity: 0.33;
                display: block;
            }
        }

        .form{
            &-control{
                background-color: var(--white);
                padding: 6px 6px 6px 0px;
                border: none !important;
                color: var(--black);
                font-weight: 500;

                &::-webkit-input-placeholder {
                    color: var(--black);
                }
                &:-ms-input-placeholder {
                    color: var(--black);
                }
                &::placeholder {
                    color: var(--black);
                }
            }
        }
    }
}

.divider{
    color: var(--text-gray);
    @extend .b2;
    margin: 15px 0;

    @media screen and (max-width: 767px){
        margin: 20px 0;
    }
    
    &:before, &:after{
        width: 42%;
        content: "";
        height: 1px;
        opacity: 0.33;
        background-color: currentColor;
    }
}

.flex{
    @include flexbox();
    @include align-items(center);
    gap: var(--bs-gutter-x);
}

.header{
    position: absolute;
    padding: 20px 30px;
    z-index: 2;
    left: 0;
    top: 0;

    img{
        max-width: 100%;
    }

    &-bar{
        background: #1f2022;
        padding: 20px 25px 20px 65px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        @media screen and (max-width: 1199px) {
            padding: 15px 16px;
        }

        @media screen and (max-width: 767px){
            flex-wrap: wrap;
        }
    }

    &-logo{
        @media screen and (max-width: 991px) {
            img{
                display: block;
                max-height: 32px;
            }
        }
    }

    &-controls{
        @extend .flex, .flex-wrap;
        gap: 10px;

        @media screen and (max-width: 991px){
            gap: 6px;
        }

        @media screen and (max-width: 767px){
            width: 100%;
            margin-top: 8px;
        }

        .dropdown{
            &-toggle{
                &.btn-toggle{
                    height: 43px;
                    border-radius: 10px;
                    background-color: var(--bg-black-2A2B2F);

                    @media screen and (max-width: 991px){
                        height: 37px;
                    }
                }

                img{
                    display: block;
                }

                &:after{
                    display: none;
                }
            }
        }
    }
    
    &-item{
        gap: 8px;
 		cursor: pointer;
        @include flexbox();
        padding: 11px 14px;
        border-radius: 10px;
        color: var(--text-gray);
      
        @include align-items(center);
        background-color: transparent;
        border: 1px solid var(--border);
		@extend .text-600, .text-14, .ease;

        &.btn-red{
            background-color: var(--red);
            border-color: var(--red);
            color: var(--white);
            font-weight: 600;
            img{
                filter: none !important;
            }
        }

        &:hover{
            color: var(--white);

            img{
                filter: invert(4) brightness(2.5);
            }
        }

        @media screen and (max-width: 991px){
            gap: 6px;
            font-size: 13px;
            padding: 8px 10px;
        }
    }
}

.headerlogo{
    position: absolute;
    padding: 20px 30px;
    z-index: 2;
    left: 0;
    top: 0;

    img{
        max-width: 100%;
    }

    &-bar{
        background: #1f2022;
        padding: 20px 25px 20px 65px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        @media screen and (max-width: 1199px) {
            padding: 15px 16px;
        }

        @media screen and (max-width: 767px){
            flex-wrap: wrap;
        }
    }

    &-logo{
        @media screen and (max-width: 991px) {
            img{
                display: block;
                max-height: 32px;
            }
        }
    }

    &-controls{
        @extend .flex, .flex-wrap;
        gap: 10px;

        @media screen and (max-width: 991px){
            gap: 6px;
        }

        @media screen and (max-width: 767px){
            width: 100%;
            margin-top: 8px;
        }

        .dropdown{
            &-toggle{
                &.btn-toggle{
                    height: 43px;
                    border-radius: 10px;
                    background-color: var(--bg-black-2A2B2F);

                    @media screen and (max-width: 991px){
                        height: 37px;
                    }
                }

                img{
                    display: block;
                }

                &:after{
                    display: none;
                }
            }
        }
    }
    
    &-item{
        gap: 8px;
 		cursor: pointer;
        @include flexbox();
        padding: 11px 14px;
        border-radius: 10px;
        color: var(--text-gray);
      
        @include align-items(center);
        background-color: transparent;
        border: 1px solid var(--border);
		@extend .text-600, .text-14, .ease;

        &.btn-red{
            background-color: var(--red);
            border-color: var(--red);
            color: var(--white);
            font-weight: 600;
            img{
                filter: none !important;
            }
        }

        &:hover{
            color: var(--white);
        }

        @media screen and (max-width: 991px){
            gap: 6px;
            font-size: 13px;
            padding: 8px 10px;
        }
    }
}

.app{
    &-blogin{
        padding-top: 40px;
        padding-bottom: 40px;

        &-banner, &-row{
            min-height: calc(100vh - 80px);
        }

        &-inner{
            max-width: 530px;
            margin: auto;
            width: 100%;
        }
    }

    &-content{
        margin-left: 300px;
        border-radius: 25px 0 0;
        min-height: calc(100vh - 84px);
        background-color: var(--bg-black-2A2B2F);

        @media screen and (max-width: 1199px){
            margin-left: 0;
            border-radius: 0;
        }
    }
 &-contain{
        // max-width: 1086px;
        margin: auto;
        width: 100%;
    }
}

.badge{
    font-size: 10px;
    font-weight: 700;
    padding: 7px 9px;
    white-space: wrap;
    border-radius: 8px;
 align-items: center;
    display: inline-flex;

    img{
        margin-left: 5px;
    }

    &.bg{
        &-danger{
            color: var(--red);
            border: 1px solid var(--red);
            background-color: rgba(#EE6A5F, 0.1) !important; 
        }

        &-success{
            color: var(--green);
            background-color: var(--green-light) !important; 
        }

        &-primary{
            color: var(--blue);
            background-color: var(--blue-light) !important; 
        }

        &-green{
            &-light{
                color: var(--green-light);
                border: 1px solid var(--green-light);
                background-color: rgba(#20E2D0, 0.1) !important; 
            }
        }

        &-blue{
            color: var(--blue);
            border: 1px solid var(--blue);
            background-color: rgba(#00C2FF, 0.1) !important; 
        }

        &-muted{
            background-color: var(--bg-black-2A2B2F);
            border: 1px solid #ABADB4;
            color: #ABADB4;
        }
    }
}

.upload{
    &-area{
        min-height: 475px;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            min-height: 360px;
        }

        @media screen and (max-width: 991px) {
            min-height: 260px;
        }
    }
}

.dropdown{
    &-menu{
        background-color: var(--border);

        a{
            color: var(--text-gray);
            font-weight: 600;
            font-size: 13px;

            + a{
                border-top: 1px solid var(--border);
            }

            &:hover{
                background-color: var(--blue) !important;
                 color: var(--text-black);
            }
        }
    }

    &-toggle{
        background-color: var(--bg-black) !important;
        @extend .flex, .text-14, .text-700;
        gap: 10px;

        &:after{
            width: 10px;
            height: 10px;
            margin-top: -4px;
            margin-left: auto;
            border-style: solid;
            transform: rotate(45deg);
            border-width: 0 2px 2px 0 !important;
            border-right-color: #525356 !important;
            border-bottom-color: #525356 !important;
        }

        img{
            min-width: 25px;
            height: 25px;
            width: 25px;
        }
    }
}

.sidebar{
    background-color: var(--bg-black);
    height: calc(100vh - 84px);
    padding: 15px 20px;
    position: fixed;
    overflow: auto;
    @include flexbox();
    @include flex-direction(column);
    @extend .ease;
    width: 300px;
    z-index: 1021;
    top: 84px;
    left: 0;

    @media screen and (max-width: 1199px) {
        top: 0;
        left: -300px;
        height: 100vh;

        &.active{
            left: 0px;
        }
    }

    &-menu{
        flex-grow: 1;

        .accordion{
            &-item{
                border: none;
                overflow: hidden;
                margin-bottom: 15px;
                border-radius: 12px !important;
                background-color: var(--bg-black-2A2B2F);
            }
            
            &-button{
                background-color: var(--bg-black-2A2B2F);
                padding: 12px 20px;
                box-shadow: none;
                font-weight: 700;
                font-size: 15px;
                color: $white;
                border: none;

                img{
                    margin-right: 12px;
                    max-height: 24px;
                    max-width: 24px;
                }

                &[aria-expanded="false"]{
                    img{
                        filter: contrast(0.5) brightness(0.8);
                    }

                    &:after{
                        filter: brightness(0) invert(1);
                        opacity: 0.2;
                    }
                }

                &[aria-expanded="true"]{
                    @extend .grad;
                }
            }

            &-body{
                padding: 0;
                
                a{
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 12px 20px;
                    color: var(--text-gray);

                    &[disabled]{
                        opacity: 0.8;
                        pointer-events: none;
                    }

                    &:hover, &.active{
                        background-color: rgba(32, 226, 208, 0.12);

                        img{
                            filter: contrast(1) brightness(1) !important;
                        }

                        span{
                            @extend .text-grad;
                            position: relative;

                            .badge{
                                position: absolute;
                                top: -8px;
                                left: 101%;
                                border: 1px solid;
                                padding: 2px 4px;
                            }
                        }
                    }

                    img{
                        margin-right: 14px;
                        max-height: 20px;
                        max-width: 20px;
                    }

                    &:not(.active){
                        img{
                            filter: contrast(0) brightness(0.8);
                        }
                    }

                    + a{
                        border-top: 1px solid #57595C;
                    }
                }
            }
        }
    }

    &-footer{
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        color: var(--muted);

        .d-flex{
            gap: 15px;
            margin-bottom: 10px;
        }
    }
}

.nav{
    &-tabs{
        border-bottom: 3px solid var(--border);       
        padding: 15px 10px;
        gap: 30px;

        @media screen and (max-width: 767px) {
            gap: 12px;
        }

        .nav-link{
            background: none !important;
            @extend .text-22, .text-700;
            color: var(--text-muted);
            border: none;
            padding: 0;

            @media screen and (max-width: 767px) {
                font-size: 16px;
            }

            &.active{
                color: var(--green-light);               
            }
        }
    }
}

.status{
    &-card{
        gap: 8px;
        padding: 16px 20px;
        border-radius: 12px;
        background-color: var(--bg-black);

        img{
            margin: auto;
            display: block;
            max-width: 100%;
        }

        h5{
            font-size: 17px;
            @extend .text-700;
            color: var(--white);
            margin-bottom: 1px;
        }
        h7{
            font-size: 15px;
            @extend .text-700;
            color: var(--white);
            margin-bottom: 1px;
        }

        h6{
            margin-bottom: 0;
            color: var(--text-gray);
            @extend .text-600, .text-14;
        }
        h8{
            font-size: 13px;
            margin-bottom: 0;
            color: var(--text-gray);
            @extend .text-600, .text-14;
        }
    }
}

.box{
    border-radius: 12px;
    background-color: var(--bg-black);

    .box{
        @media screen and (max-width: 575px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .status-card{
        background-color: var(--bg-black-2A2B2F);
    }

    &-dropdown{
        
        button{
            min-width: 100px;
            padding: 6px 8px;
            border-radius: 25px;
        }
    }
}

.countdown{
    text-align: center;
    letter-spacing: 0.2em;
    @extend .text-700, .text-18;

    span{
        background-color: var(--bg-black-2A2B2F);
        border-radius: $space;
        line-height: 30px;
        padding-left: 4px;
        margin-right: 6px;
        width: 60px;

        @media screen and (max-width: 768px) {
            width: 45px;
        }

        ~ span{
            margin-left: 3px;
        }
    }
}

.circle{
    &-timer{
        position: relative;
        height: 102px;
        width: 102px;
        z-index: 1;

        img{
            position: absolute;
            margin: auto;
            z-index: -1;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
        }

        
        .CircularProgressbar{
            .CircularProgressbar{
                &-trail{
                    stroke: rgba(#62646A, 0.5) !important;
                }
            }
        }

        &-pie{
            .CircularProgressbar{
                .CircularProgressbar{
                    &-trail{
                        stroke: #EE6A5F !important;
                    }
                
                    &-path{
                        stroke: #20E2D0 !important;
                    }
                }
            }
        }
    }
}


.chart{
    &-filter{
        text-transform: uppercase;
        gap: 15px;

        @media screen and (max-width: 1199px) {
            gap: 8px;
        }

        button{
            @extend .text-13, .text-600;
            color: var(--muted);
            background: none;
            border: none;
            padding: 0;
            margin: 0;

            &:hover, &.active{
                color: var(--white);
            }
        }
    }

    &-header{
        padding: 6px;
        text-align: center;
        border-top: 1px solid var(--border);
        border-bottom: 1px solid var(--border);

        > div{
            padding: 6px;
            flex-grow: 1;
            
            + div{
                border-left: 1px solid var(--border);
            }
        }

        select{
            height: 44px;
            border: none;
            padding: 1em;
            font-size: 13px;
            font-weight: 600;
            color: var(--text-gray);
            background: var(--bg-black-2A2B2F) url('./asserts/IMAGES/select-arrow.svg') no-repeat center right 10px / auto;

            option{
                border: 1px solid var(--text-gray);
                background: var(--border);
                padding: 5px;
            }
        }

        h6{
            color: var(--text-gray);
            margin-bottom: 4px;
            font-weight: 600;
            font-size: 11px;
        }

        h5{
            margin-bottom: 0;
            font-weight: 700;
            font-size: 13px;
        }
    }
}

.dot{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 12px;
    display: inline-block;
    vertical-align: middle;
}

.table{
    background-color: var(--bg-black);
    color: var(--white);

    &-responsive{
        border-radius: 12px;
        background-color: var(--bg-black);

        .table{
            margin-bottom: 0;
        }
    }

    &-border{
        .table-responsive{
            border: 1px solid var(--border);

            table{
                tr{
                    td, th{
                        border: 1px solid var(--border);

                        &:first-child{
                            border-left: none !important;
                        }
                        
                        &:last-child{
                            border-right: none !important;
                        }
                    }

                    
                    &:first-child{
                        border-top: none !important;

                        th, td{
                            border-top: none !important;
                        }
                    }
    
                    &:last-child{
                        border-bottom: none !important;
                        
                        th, td{
                            border-bottom: none !important;
                        }
                    }
                }
            }
        }
    }

    &-bordered{

        th, td{
            background-color: transparent !important;
            color: #BDBEC1 !important;
        }
    }

    &-striped{
        tbody{
            tr{
                &:nth-child(2n+1){
                    td{
                        background-color: #252729;
                    }
                }

                &:nth-child(2n+2){
                    td{
                        background-color: var(--bg-black);
                    }
                }
            }
        }
    }
    
    th{
        font-size: var(--14);
        color: var(--text-gray);
        background-color: #3E3F41;
    }

    td{
        font-size: var(--14);
        color: var(--white);
        
        .d-flex{
            @extend .gap-2;
        }
    }
    
    th, td{
        border: none;
        box-shadow: none;
        font-weight: 600;
        padding: 14px 16px;
        white-space: nowrap;
        vertical-align: middle;
        
        @media screen and (max-width: 991px) {
            padding: 12px 16px;
        }

        small{
            @extend .text-muted;
        }

        img{
            max-width: 28px;
        }
    }

    &-sm{
        th, td{
            padding: 12px 8px;
        }
    }

    &-box{
        .table{
            &-responsive{
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 2px solid var(--border);

                td{
                    background-color: var(--bg-black) !important;
                }
            }
        }

        .box{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.sort{
    background: transparent url('./asserts/IMAGES/sort-icon.svg') no-repeat center right / auto;
    display: inline-block;
    padding-right: 12px;
}

.paginate{
    gap: 8px;
    @include flexbox();
    @include align-items(center);
    

    
    button, a{
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        border: 1px solid var(--border);
        color: var(--white) !important;
        border-radius: 6px;
        background: none;
        height: 30px;
        width: 30px;

        &:hover, &.active{
            @extend .grad;
        }
    }
}

.swap{
    &-card{
        gap: 8px;
        padding: 14px 12px;
        border-radius: 12px;
        border: 1px solid var(--border);

        @media screen and (max-width: 575px) {
        //     flex-wrap: wrap;
            padding: 6px;
            gap: 4px;
        }


        img{
            display: block;
            // max-width: 100%;

            @media screen and (max-width: 575px){
                width: 25px;
            }
        }

        h5:not(.text-gray){
            font-size: 17px;
            @extend .text-700;
            color: var(--white);
            margin-bottom: 1px;

            @media screen and (max-width: 575px) {
                font-size: 11px;
            }
        }

        h6{
            margin-bottom: 0;
            white-space: nowrap;
            color: var(--text-gray);
            @extend .text-600, .text-14;
        }

        hr{
            opacity: 1;
            margin: 8px 0;
            border-width: 2px;
            border-color: var(--white);
        }

        .flex-grow-1:not(:first-child){
            margin-left: 25px;
            padding-left: 25px;
            border-left: 1px solid var(--border);

            // @media screen and (max-width: 575px){
            //     order: 1;
            //     padding-left: 0;
            //     border-left: none;
            // }

            @media screen and (max-width: 1199px) {
                margin-left: 12px;
                padding-left: 10px;
            }
            
            @media screen and (max-width: 575px) {
                margin-left: 2px;
                padding-left: 6px;
            }
            
        }
        > div:nth-child(2):not(.flex-grow-1):not(.row){
            @media screen and (max-width: 575px) {
                width: 50px;
                min-width: 50px;
            }
        }
        .btn{
            min-width: 80px;

            @media screen and (max-width: 575px){
                margin-left: auto;
                min-width: 55px;
            }
        }
    }

    &-value{
        h6{
            margin-bottom: 5px;
            font-size: var(--13);
            color: var(--text-gray);
        }

        h4{
            font-size: var(--16);
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}

.input{
    &-reset{
        outline: none !important;
        border: none !important;
        color: var(--white);
        background: none;
        @extend .text-18;
        font-weight: 700;
        width: 100%;
        padding: 0;
    }
}

.border{
    &-bottom{
        border-color: var(--border) !important;
    }
}

.tooltip{
    z-index: 1;
    opacity: 1;
    margin-left: 8px;
    position: relative;
    vertical-align: 2px;
    display: inline-block;

    span{
        transform: translateY(15px);
        @extend .ease, .grad;
        font-size: var(--12);
        color: var(--white);
        border-radius: 4px;
        margin-bottom: 5px;
        position: absolute;
        text-align: center;
        visibility: hidden;
        width: 200px;
        bottom: 100%;
        padding: 8px;
        left: -100px;
        opacity: 0;
    }

    &.align{
        &-right{
            span{
                left: auto;
                right: 0;
            }
        }

        &-left{
            span{
                left: 0;
            }
        }
    }
    
    &:hover{
        span{
            transform: translateY(3px);
            visibility: visible;
            opacity: 1;
        }
    }
}

.burn{
    &-card{
        border-radius: 12px;
        background-color: var(--bg-black-2A2B2F);

        .countdown{
            span{
                background-color: rgba(#0E0E0E, 0.2);
            }
        }

        .swap-card{
            background-color: var(--bg-black);
        }
    }
}

.sc-chart{
    width: 90px;
    display: inline-block;
}

.price{
    &-list{
        h4{
            white-space: nowrap;
        }
    }
}

.staking{
    &-card{
        max-width: 360px;
        margin: auto;
        width: 100%;
        
        &-header{
            &-icon{
                position: relative;
                z-index: 1;

                img{
                    + img{
                        position: absolute;
                        z-index: -1;
                        left: -5px;
                        top: -3px;
                    }
                }
            }

            &-info{
                span{
                    border: 1px solid var(--blue);
                    display: inline-block;
                    border-radius: 25px;
                    text-align: center;
                    color: var(--blue);
                    line-height: 20px;
                    margin-left: 4px;
                    font-weight: 500;
                    font-size: 12px;
                    padding: 0 8px;

                    + span{
                        color: var(--text-black);
                        background-color: var(--blue);
                    }
                }
            }
        }
    }
}

.launch{
    &-pad{
        &-card{
            border: 1px solid var(--border);
            border-radius: 10px;
            font-weight: 500;

            > .d-flex{
                gap: 15px;
            }
        }
    }
}

.modal{
    &-lg{
        --bs-modal-width: 900px;
    }

    .btn{
        &-close{
            padding: 0;
            width: 32px;
            height: 32px;
            background-size: 32px;
            --bs-btn-close-bg: url('./asserts/IMAGES/close-icon.svg');
        }
    }

    &-content{
        background-color: var(--bg-black-2A2B2F);
    }

    &-header{
        border: none;
        padding: 25px 30px;

        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }

    &-title{
        gap: 8px;
        @extend .flex;

        h4{
            font-size: var(--20);
        }
    }

    &-body{
        padding: 15px 30px 35px;
        
        @media screen and (max-width: 767px) {
            padding: 15px 15px 30px;
        }
    }
}

.progress{
    background-color: var(--border);
    --bs-progress-bar-bg: var(--green-light);
    --bs-progress-height: 26px;

    &-shadow{
        overflow: visible;
        
        .bg{
            &-danger{
                box-shadow: 0 0 38px rgba(#EE6A5F, 0.4);
            }
            
            &-green{
                &-light{
                    box-shadow: 0 0 38px rgba(#20E2D0, 0.4);
                }
            }
        }

        div{
            border-radius: inherit;
        }
    }
}

.time{
    &-green{
        .countdown{
            span{
                background-color: var(--bg-black);
                color: var(--green-light);
            }
        }
    }
}

.redeem{
    &-card{
        .dropdown-toggle, .form-control{
            max-width: 225px;
            margin: auto;
            width: 100%;
        }

        @media screen and (max-width: 767px) {
            border: 1px solid var(--border);
            border-radius: 12px;
            margin-bottom: 15px;
            padding: 12px;
        }
    }
}

.redeem{
    &-chart{
        position: relative;
        min-height: 400px;
        margin: 20px 0;
        
        @media screen and (max-width: 767px){
            margin-bottom: 10px;
            margin-left: 20px;
            min-height: 440px;
            margin-top: 10px;
        }

        h5{
            line-height: 1;
        }

        &:before{
            top: 50px;
            width: 3px;
            content: "";
            left: -25px;
            bottom: 20px;
            position: absolute;
            background-color: var(--blue);

            @media screen and (max-width: 991px) {
                bottom: 100px;
                left: -20px;
                top: 22px;
            }

            @media screen and (max-width: 767px){
                top: -30px;
            }

            @media screen and (max-width: 575px){
                bottom: 78px;
            }
        }

        &-row{
            width: 100%;
            position: relative;

            &:before{
                top: 48px;
                
                right: 100%;
                height: 3px;
                width: 60px;
                content: "";
               
                position: absolute;
                background-color: var(--blue);

                @media screen and (max-width: 991px) {
                    bottom: 0;
                    top: 20px;
                    margin: 0;
                    width: 40px;
                }

                @media screen and (max-width: 575px){
                    top: 28px;
                }
            }

            &:first-child, &:last-child{
                &:before{
                    width: 25px;

                    @media screen and (max-width: 991px) {
                        width: 20px;
                    }
                }
            }

            .form-control{
                background-color: var(--border);
                max-width: 160px;
                padding: 0 20px;
                height: 42px;
                width: 100%;
            }
        }

        &-cell{
            background-color: #294142;
            padding-right: 10px;
            border-radius: 6px;
            min-height: 42px;
        }

        &-percent{
            background-color: var(--muted);
            @extend .text-14, .text-700;
            border-radius: inherit;
            color: var(--white);
            text-align: center;
            line-height: 42px;
            min-width: 90px;
            padding: 0 10px;

            @media screen and (max-width: 575px){
                line-height: 58px;
            }
        }

        &-mid{
            gap: 15px;
            flex-grow: 1;
            padding: 4px 0;
            text-align: center;
            @extend .flex, .justify-content-center;

            @media screen and (max-width: 575px){
                flex-direction: column;
                gap: 5px;
            }

            .dropdown-toggle{
                height: 31px;
                min-width: 150px;
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                img{
                    min-width: 13px;
                    height: 13px;
                    width: 13px;
                }
            }
}
    }
    
}

.popup{
    &-icon{
        svg{
            width: 80px;
            height: 80px;
            margin: auto;
            display: block;
            border-radius: 50%;
            border: 2px solid #F5C451;
        }
    }
}
.no-caret::after {
    display: none !important;
}
.footer-cartoon {
    transition: all .5s linear;
    position: fixed;
    cursor: pointer;
    width: 160px;
    z-index: 999;
    right: 10px;
    bottom: 0;
}
.modal-dashboard {
    border-radius: 16px;
    padding: 24px;
     border: none;
     @media screen and (max-width: 575px){
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        // background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .3rem;
        outline: 0;
     }
}

.balance{
    &-diagram{
        position: relative;
        max-width: 465px;
        margin: auto;
        width: 100%;
        z-index: 1;

        @media screen and (max-width: 575px){
            top: 50px;
            min-width: 465px;
            transform-origin: center;
            transform: scale(0.65);
            position: absolute;
        }

        &-outer{
            @media screen and (max-width: 575px){
                height: 410px;
                position: relative;
            }
        }

        &-poll{
            max-width: 100%;
            display: block;
            margin: auto;

            // @media screen and (max-width: 575px){
            //     max-height: 300px;
            // }
        }

        &-angle{
            position: absolute;
            margin: auto;
            z-index: -1;
            width: 85%;
            top: 15.8%;
            right: 0;
            left: 0;
        }
    }

    &-weight{
        position: absolute;
        z-index: 1;
        top: 21.5%;
        
        &-left{
            left: 0;
        }
        
        &-right{
            right: 0;
        }

        &-info{
            text-align: center;
            font-size: var(14);
            font-weight: 700;
            margin-top: 8px;

            span{
                display: block;
                font-size: var(12);
            }
        }

        &-image{
            max-width: 100%;

            // @media screen and (max-width: 575px){
            //     max-width: 110px;
            // }
        }

        &-group{
            position: relative;
        }

        &-icons{
            text-align: center;
            position: absolute;
            max-width: 105px;
            margin: auto;
            bottom: 20px;
            width: 85%;
            right: 0;
            left: 0;

            // @media screen and (max-width: 575px) {
            //     bottom: 12px;
            //     min-width: 107px;
            //     transform: scale(0.7);
            //     transform-origin: bottom center;
            // }

            img{
                width: 28px;
                margin: -10px -4px;

                &:nth-child(6){
                    position: absolute;
                    bottom: 25px;
                    left: 16px;
                }

                &:nth-child(7){
                    position: absolute;
                    bottom: 25px;
                    left: 43px;
                }

                &:nth-child(8){
                    position: absolute;
                    bottom: 25px;
                    left: 70px;
                }

                &:nth-child(9){
                    position: absolute;
                    bottom: 40px;
                    left: 29px;
                }

                &:nth-child(10){
                    position: absolute;
                    bottom: 40px;
                    left: 57px;
                }

                &:nth-child(11){
                    position: absolute;
                    bottom: 55px;
                    left: 42px;
                }
            }
        }
    }
}


@media screen and (max-width: 575px) {
    .border-start.col-sm-3 {
        border-top: 1px solid var(--bs-border-color);
        margin-top: 15px;
        padding-top: 15px;
        border-left: none !important;
    }
}