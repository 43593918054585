@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

p{
    color: var(--text-gray);

    &:last-child{
        margin-bottom: 0;
    }

    a{
        color: var(--text-black);
    }
}

label{
    color: var(--text-black);
    @extend .text-16, .text-500;
}

.ease{
    @include transition(all 0.35s linear);
}

// type
h3, h4, h5, h6{
    font-weight: 700; 
    line-height: 1.46;   
}
h1{
    font-size: var(--28);
    font-weight: 700;
}

h2{
    font-size: var(--22);
    font-weight: 600;
}

h3{
    font-size: var(--19);
    margin-bottom: 0.7em;
}

h4{
    letter-spacing: -0.41px;
    font-size: var(--18);
}

h5{
    font-size: var(--16);

    .tooltip{
        img{
            width: 15px;
            height: 15px;
        }
    }
}

h6{
    font-size: var(--14);

    .tooltip{
        img{
            width: 15px;
            height: 15px;
        }
    }
}

.bg{
    &-body{
        background-color: var(--bg-black) !important;
    }

    &-dark{
        background-color: var(--bg-black-2A2B2F) !important;
    }

    &-danger{
        background-color: var(--red) !important;
        color: var(--red);
    }
    
    &-green{
        background-color: var(--green) !important;
        color: var(--green);
        
        &-light{
            background-color: var(--green-light);
            color: var(--green-light);
        }
    }

    &-grad{
        @extend .grad;
    }
}

.text{
    &-block{
        color: var(--text-black);

        p{
            color: inherit;
        }
    }

    &-blue{
        color: var(--blue);
    }

    &-red{
        color: var(--red);
    }

    &-green{
        color: var(--green);
        
        &-light{
            color: var(--green-light) !important;
        }
    }

    &-gray{
        color: var(--text-gray);
    }
    
    &-muted{
        color: var(--text-muted) !important;
    }

    &-white{
        &-07{
            color: rgba(#fff, 0.7);
        }
        &-08{
            color: rgba(#fff, 0.8);
        }
    }

    &-700{
        font-weight: 700;
    }

    &-600{
        font-weight: 600 !important;
    }

    &-500{
        font-weight: 500;
    }

    &-400{
        font-weight: 400;
    }

    &-300{
        font-weight: 300;
    }

    &-200{
        font-weight: 200;
    }

    &-28{
        font-size: var(--28);
        // line-height: 38px;
    }

    &-22{
        font-size: var(--22);
        // line-height: 28px;
    }

    &-20{
        font-size: var(--20);
        // line-height: 25px;
    }

    &-18{
        font-size: var(--18);
        // line-height: 24px;
    }

    &-16{
        font-size: var(--16);
        // line-height: 24px;
    }

    &-14{
        font-size: var(--14);
        // line-height: 20px;
    }

    &-13{
        font-size: var(--13);
        // line-height: 16px;
    }

    &-12{
        font-size: var(--12);
    }

    &-9{
        font-size: var(--9);
    }
}

hr{
    opacity: 1;
    margin: 24px 0;
    border-color: var(--border);
}

// spacing
.mb{
    &-20{
        margin-bottom: var(--20);
    }

    &-24{
        margin-bottom: var(--24);
    }

    &-40{
        margin-bottom: var(--40);
    }
}

.px{
    &-100{
        padding-left: var(--100);
        padding-right: var(--100);
    }
}

.pb{
    &-100{
        padding-bottom: var(--100);
    }
}

.p{
    &-24{
        padding: 24px;

        @media screen and (max-width: 991px) {
            padding: 20px 15px;
        }
    }
}